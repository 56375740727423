<template>
  <van-action-sheet v-model="showAction" class="choose-lg" :title="$t('settings.changeLanguage')">
    <div class="content">
      <div :class="{'is-active':!isEnglish}" class="content-item tagalog" @click="isEnglish=false">
        <p class="abbreviation">T</p>
        <p>Tagalog</p>
      </div>
      <div :class="{'is-active':isEnglish}" class="content-item english" @click="isEnglish=true">
        <p class="abbreviation">E</p>
        <p>English</p>
      </div>
    </div>
    <submit-btn :sub-text="$t('public.confirmBtn')" submit-flag @submit="submit" />
  </van-action-sheet>
</template>

<script>
import i18n from '@/i18n'
export default {
  data() {
    return {
      showAction: false,
      isEnglish: i18n.locale === 'en'
    }
  },
  watch: {
    isEnglish: {
      handler(val) {
        i18n.locale = val ? 'en' : 'ph'
        const lgObj = JSON.stringify({ key: (val ? 'en' : 'ph'), val: (val ? 'en' : 'ph') })
        localStorage.setItem('isEn', lgObj)
        this.showAction = false
        console.log('changeLG' + i18n.locale, i18n.locale, '')
        this.$H5Object['changeLG' + i18n.locale]()
      }
    }
  },
  created() {
    // const lgObj = JSON.parse(localStorage.getItem('isEn') || '{}')
    // this.isEnglish = lgObj.val + '' === '1'
  },
  methods: {
    submit() {
      this.showAction = false
    }
  }
}
</script>
<style lang="scss" scoped>
.choose-lg{
  border-radius: 48px 48px 0px 0px;
  .van-action-sheet__header{
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    padding-top: 48px;
    .van-action-sheet__close{
      width: 44px;
      height: 44px;
      top: 48px;
      left: 40px;
      background: url('~@/assets/images/public/head-back-black-icon.png') no-repeat center center;
      background-size: 100%;
      &::before{
        display: none;
      }
    }
  }
  .van-action-sheet__content{
    padding: 40px;
  }
  .content{
    display: grid;
    margin-bottom: 48px;
    grid-template-columns: 50% 50%;
    .content-item{
      height: 268px;
      background: #FFFFFF;
      box-shadow: 0px 4px 24px 0px #F0F0F0;
      border-radius: 32px;
      position: relative;
      padding-left: 50px;
      .abbreviation{
        font-size: 56px;
        font-weight: bold;
        color: #FF7245;
        padding-top: 104px;
        padding-bottom: 40px;
      }
      &::before{
        content: '';
        position: absolute;
        width: 88px;
        height: 108px;
        top: 0;
        right: 60px;
        background: url('~@/assets/images/userCenter/lg-check-icon.png') no-repeat center center;
        background-size: 100%!important;
      }
      p{
        font-size: 30px;
        font-weight: bold;
        color: #1C1D21;
      }
    }
    .is-active.content-item::before{
      background: url('~@/assets/images/userCenter/lg-checked-icon.png') no-repeat center center;
    }
    .english{
      margin-left: 18px;
      .abbreviation{
        color: #005FE9;
      }
    }
    .tagalog{
      margin-right: 18px;
    }
  }
}
</style>
