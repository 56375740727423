<template>
  <div>
    <base-template :sub-text="$t('settings.loginOut')" :submit-flag="true" :show-btn="true" @submit="submit">
      <div class="list-box">
        <ul>
          <li v-for="(item,index) in dataList" :key="index" :class="{'no-go':item.isContent}" class="list-item" @click="item.clickEvent?item.clickEvent():goLink(item.link)">
            <span :class="item.icon" class="icon" />
            <span class="custom-title">{{ $t(item.name ) }}</span>
            <span v-if="item.isContent" class="cont">{{ item.cont }}</span>
          </li>
        </ul>
      </div>
    </base-template>
    <base-dialog
      v-if="dialogVisible"
      ref="baseDialog"
      show-cancel-button
      is-reverse
      :confirm-button-text="$t('settings.cancleBtn')"
      :cancel-button-text="$t('settings.confirmBtn')"
      :message="dialogMsg"
      :title="dialogTitle"
      @confirm="confirm"
      @cancel="cancel"
    />
    <choose-lg ref="chooseLg" />
  </div>
</template>

<script>
import { cancelAccount } from '@/api/axios/index'
import ChooseLg from './components/ChooseLg'
export default {
  components: { ChooseLg },
  data() {
    return {
      isDelete: false,
      dialogVisible: false,
      dialogMsg: this.$t('settings.loginOut'),
      dialogTitle: this.$t('settings.loginOutMsg'),
      authNumber: ['08444455551', '087788797420', '0812345678', '081234567', '0812341234'],
      dataList: [
        {
          icon: 'change-password-icon',
          name: 'settings.changePassWord',
          link: '/changePassword'
        },
        {
          icon: 'ysxy-icon',
          name: 'settings.privacy',
          link: '/privacyAgreement'
        },
        {
          icon: 'lg-change-icon',
          name: 'settings.changeLanguage',
          link: '',
          clickEvent: () => {
            this.chooseLg()
          }
        },
        {
          icon: 'version-icon',
          name: 'settings.version',
          isContent: true,
          cont: 'V' + (this.$andirodData.Ubwafwilisho_Application || ''),
          link: ''
        }
        // {
        //   icon: 'delete-icon',
        //   name: 'settings.deleteAccount',
        //   link: '',
        //   clickEvent: () => {
        //     this.cancelAccountDialog()
        //   }
        // }
      ]
    }
  },
  methods: {
    submit() {
      if (this.isDelete) {
        this.dialogTitle = this.$t('settings.deleteAccount')
        const phone = this.$andirodData.phone_number || ''
        if (this.authNumber.includes(phone)) {
          this.dialogMsg = this.$t('settings.authDeleteAccountMsg')
          return
        } else {
          this.dialogMsg = this.$t('settings.deleteAccountMsg')
        }
      } else {
        this.dialogTitle = this.$t('settings.loginOut')
        this.dialogMsg = this.$t('settings.loginOutMsg')
      }

      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.baseDialog.init()
      })
    },

    goLink(link) {
      if (link) {
        this.$router.push({ path: link })
      }
    },

    confirm() {
      if (this.isDelete) {
        this.cancelAccount()
        return
      }
      if (!this.isDelete) {
        this.$H5Object.loginOut()
      } else {
        if (this.isDelete) this.isDelete = false
      }
    },

    cancel() {
      if (this.isDelete) this.isDelete = false
    },

    // 删除账号弹窗
    cancelAccountDialog() {
      this.isDelete = true
      this.submit()
    },

    // 删除账号
    async cancelAccount() {
      try {
        const { inyimbo } = await cancelAccount() || {}
        if (inyimbo + '' === '200') {
          this.$toast.success({
            message: this.$t('settings.deleteSuccess'),
            onClose: () => {
              this.$H5Object.loginOut()
            }
          })
        }
      } catch (err) {
        this.isDelete = false
      }
    },

    // 语言选择
    chooseLg() {
      this.$refs.chooseLg.showAction = true
    }
  }
}
</script>

<style lang="scss" scoped>
.is-content{
  padding-top: 0;
  .list-box{
    padding-top: 32px;

    .list-item{
      position: relative;
      width: 100%;
      height: 72px;
      line-height: 72px;
      background: #FFFFFF;
      margin-bottom: 48px;

      .icon{
        width: 72px;
        height: 72px;
        display: table-cell;
        position: relative;
        background-color: #EEEEEE;
      }
      .cont{
        font-size: 32px;
        font-weight: 400;
        color: rgba(0,0,0,0.3);
        position: absolute;
        top: 0;
        right: 0;
      }
      .custom-title{
        display: table-cell;
        font-size: 30px;
        color: #000000;
        padding-left: 16px;
      }
      .change-password-icon{
        background: url(../../../assets/images/userCenter/change-password-icon.png) no-repeat center center;
        background-size: 100%;
      }
      .lg-change-icon{
        background: url(../../../assets/images/userCenter/lg-change-icon.png) no-repeat center center;
        background-size: 100%;
      }
      .about-us-icon{
        background: url(../../../assets/images/userCenter/about-us-icon.png) no-repeat center center;
        background-size: 100%;
      }
      .ysxy-icon{
        background: url(../../../assets/images/userCenter/ysxy-icon.png) no-repeat center center;
        background-size: 100%;
      }
      .delete-icon{
        background: url(../../../assets/images/userCenter/delete-icon.png) no-repeat center center;
       background-size: 100%;
      }

      .version-icon{
        background: url(../../../assets/images/userCenter/version-icon.png) no-repeat center center;
        background-size: 100%;
      }

    }
    .list-item::after{
      content: '';
      width: 48px;
      height: 48px;
      position: absolute;
      background: url(../../../assets/images/userCenter/center-next-icon.png) no-repeat center center;
      background-size: auto 100%;
      top: 50%;
      margin-top: -24px;
      right: 0;
    }
    .list-item.no-go::after{
      display: none;
    }
  }
}
 :deep(.btn-box){
  margin-top: 16px;
}
</style>

